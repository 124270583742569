import { AudioRecorder } from "../audiorecorder/AudioRecorder";
import { Textarea } from "baseui/textarea";
import { Button } from "baseui/button";
import { SendIcon, TextIcon } from "../ui/svg";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
    appAudioOnState,
    appAudioSrcHistoryState,
    appAudioSrcPlayingState,
    appBackgroundColorState,
    chatAiAutoSendState,
    chatAiHistoryState,
    chatAiInputTextState,
    chatAiLoadingState,
} from "../../states/chatai";
import { useChatAi } from "../../hooks/useChatAi";
import { useTextToSpeech } from "../../hooks/useTextToSpeech";
import { HFlex } from "../containers/HFlex";
import { contrastFilterColor } from "../../utils/colors";
import { useNotARobot } from "../../hooks/useNotARobot";
import { SpacerExpand } from "../containers/Spacer";
import { windowIsMobileState } from "../../states/window";
import { ButtonWithTts } from "../ui/ButtonWithTts";

export function BottomBar() {
    const { theme, strings } = useTheme();
    const loading = useRecoilValue(chatAiLoadingState);
    const [inputText, setInputText] = useRecoilState(chatAiInputTextState);
    const [autoSend, setAutoSend] = useRecoilState(chatAiAutoSendState);
    const [userQueriesCount, setUserQueriesCount] = useState(0);
    const { sendQuery } = useChatAi();
    const setAudioSrcHistory = useSetRecoilState(appAudioSrcHistoryState);
    const [audioSrcPlaying, setAudioSrcPlaying] = useRecoilState(appAudioSrcPlayingState);
    const tts = useTextToSpeech();
    const audioOn = useRecoilValue(appAudioOnState);
    const backgroundColor = useRecoilValue(appBackgroundColorState);
    const { isRobot } = useNotARobot();
    const [showTextArea, setShowTextArea] = useState(false);
    const isMobile = useRecoilValue(windowIsMobileState);

    useEffect(() => {
        if (autoSend) {
            onSendClick();
            setAutoSend(false);
        }
    }, [inputText, autoSend]);

    const onSendClick = () => {
        if (loading) {
            return;
        }
        if (isRobot()) {
            return;
        }
        setShowTextArea(false);
        setUserQueriesCount(userQueriesCount + 1);
        sendQuery(inputText, async (query) => {
            if (audioOn) {
                tts.ttsForQuery(query);
            }
        });
    }

    const onAudioText = (text: string) => {
        setAutoSend(true);
        setInputText(text);
    }

    function onAudioStarted() {
        setAudioSrcHistory(old => [...old, ...audioSrcPlaying]);
        setAudioSrcPlaying([]);
    }

    const iconSize = 32;

    return (
        <HFlex
            alignCenter
            spacing
            style={{ width: "100%", paddingTop: "16px" }}
        >
            <SpacerExpand/>
            <SpacerExpand/>
            <div>
                <AudioRecorder onText={onAudioText} onStarted={onAudioStarted}/>
            </div>
            <SpacerExpand/>
            {showTextArea && <Textarea rows={1}
                                       size={"large"}
                                       autoFocus
                                       value={inputText}
                                       onChange={(e) => setInputText(e.target.value)}
                                       onKeyDown={event => {
                                           if (event.key === "Enter") {
                                               onSendClick();
                                               event.preventDefault();
                                           }
                                       }}
                                       placeholder={strings.Prompt.Placeholder}
                                       overrides={{
                                           Root: {
                                               style: {
                                                   flexGrow: 1,
                                                   minWidth: isMobile ? "160px" : "400px"
                                               }
                                           },
                                           Input: {
                                               style: {
                                                   '::placeholder': {
                                                       color: backgroundColor || theme.colors.contentTertiary,
                                                       filter: contrastFilterColor(backgroundColor),
                                                       transition: "color 3000ms ease-in-out",
                                                   },
                                               },
                                           },
                                       }}
            />}
            {showTextArea &&
                <div>
                    <Button
                        disabled={inputText.length === 0}
                        onClick={onSendClick}
                        $disabled={loading}
                        kind={"secondary"}
                        shape={"circle"}
                        size={"large"}
                    >
                        <SendIcon color={backgroundColor || theme.colors.contentPrimary} size={iconSize} style={{
                            filter: contrastFilterColor(backgroundColor),
                        }}/>
                    </Button>
                </div>
            }
            {!showTextArea && (
                <ButtonWithTts
                    tts={strings.Prompt.StartWriting}
                    kind={"secondary"}
                    onClick={() => setShowTextArea(true)}
                    shape={"circle"}>
                    <TextIcon size={iconSize} color={backgroundColor || theme.colors.contentPrimary} style={{
                        filter: contrastFilterColor(backgroundColor),
                    }}/>
                </ButtonWithTts>
            )}
            <SpacerExpand/>
            <SpacerExpand/>
        </HFlex>
    );
}
import { RefObject, useEffect } from 'react';
import { RecoilState, useRecoilState } from "recoil";

export const useScrolling = (ref: RefObject<HTMLElement>, state: RecoilState<boolean>): boolean => {
    const [scrolling, setScrolling] = useRecoilState(state);

    useEffect(() => {
        if (ref.current) {
            let scrollingTimeout: NodeJS.Timeout;

            const handleScrollEnd = () => {
                setScrolling(false);
            };

            const handleScroll = () => {
                setScrolling(true);
                if (scrollingTimeout) {
                    clearTimeout(scrollingTimeout);
                }
                scrollingTimeout = setTimeout(() => handleScrollEnd(), 150);
            };

            ref.current.addEventListener('scroll', handleScroll, false);
            return () => {
                if (ref.current) {
                    ref.current.removeEventListener('scroll', handleScroll, false);
                }
            };
        }
        return () => {};
    }, [ref]);

    return scrolling;
};
import { atom } from "recoil";

export const honeypotState = atom({
    key: "app.honeypot",
    default: "",
});

export const mouseMovedState = atom({
    key: "app.mousemoved",
    default: false,
});

export const startTimeState = atom({
    key: "app.start",
    default: Date.now(),
});
import { ReactNode, useEffect, useState } from "react";
import { Audio } from "./Audio";
import { LabelLarge } from "baseui/typography";
import { useTextToSpeech } from "../../hooks/useTextToSpeech";

type Props = {
    tts: string | undefined,
    style: any,
    children?: ReactNode[] | ReactNode,
}

export function LabelWithTts({ children, style, tts }: Props) {
    const [audioUrl, setAudioUrl] = useState("");
    const { fetchTextToSpeech } = useTextToSpeech();

    useEffect(() => {
        if (tts) {
            fetchTextToSpeech({ text: tts, voice: "female" }).then(res => {
                if (res.status && res.url) {
                    setAudioUrl(res.url);
                }
            });
        }
    }, [tts])

    return (
        <LabelLarge style={style}>
            {children}
            {audioUrl && <Audio play src={audioUrl}/>}
        </LabelLarge>
    );
}
import { FC, memo, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { appAudioOnState } from "../../states/chatai";

type Props = {
    src: string;
    play: boolean;
    onStarted?: () => void;
    onEnded?: () => void;
    force?: boolean;
}

export const Audio: FC<Props> = memo(({ src, play, onStarted, onEnded, force }) => {
    const isAudioOn = useRecoilValue(appAudioOnState);
    const audioRef = useRef(null);
    useEffect(() => {
        if (play) {
            playAudio();
        } else {
            stopAudio();
        }
    }, [play]);

    if (!isAudioOn && !force) {
        return null;
    }

    const stopAudio = () => {
        if (audioRef.current) {
            const current = audioRef.current as any;
            current?.pause();
        }
    }

    const playAudio = () => {
        if (audioRef.current) {
            const current = audioRef.current as any;
            current?.play().then(() => {
                console.log("Audio playing");
            }).catch((error: any) => {
                console.error("Audio playback failed:", error);
            });
        }
    }

    return <audio ref={audioRef} src={src} onPlay={onStarted} onEnded={onEnded}></audio>;
});
import { MessageCard } from "baseui/message-card";
import { ShareObject } from "../../types/share";
import { useSetRecoilState } from "recoil";
import { appShowSavedStoriesState, chatAiHistoryState } from "../../states/chatai";
import { VFlex } from "../containers/VFlex";
import { useTime } from "../../hooks/useTime";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { luminance } from "../../utils/colors";

export function StoryCard({ object }: { object: ShareObject }) {
    const setStory = useSetRecoilState(chatAiHistoryState);
    const setShow = useSetRecoilState(appShowSavedStoriesState);
    const time = useTime();

    const isVeryLight = luminance(object.color) > 0.9;
    const color = isVeryLight ? "#000000" : "#FFFFFF";

    return (
        <MessageCard
            heading={object.title}
            paragraph={<VFlex spacing>
                <LabelSmall color={color}>{object.description}</LabelSmall>
                <LabelXSmall color={color + "AA"}>{time.formatDateAndTime(object.ts)}</LabelXSmall>
            </VFlex>
            }
            buttonLabel="Take me there"
            onClick={() => {
                setStory(object.history);
                setShow(false);
            }}
            image={{
                src: object.image,
                ariaLabel: object.description,
            }}
            backgroundColor={object.color}
            overrides={{ Root: { style: { marginBottom: '30px' } } }}
        />
    );
}
import { atom, selector } from "recoil";
import { getKidResponse, QueryResponse } from "../types/chatai";
import { atomWithPersistence } from "../utils/persistence";
import { ShareObject } from "../types/share";

export const chatAiHistoryState = atomWithPersistence<QueryResponse[]>("chatai.history", []);

export const chatAiIgnoreState = atomWithPersistence<QueryResponse[]>("chatai.ignore", []);

export const chatAiLoadingState = atom<boolean>({
    key: "chatai.loading",
    default: false,
});

export const chatAiInputTextState = atom<string>({
    key: "chatai.inputtext",
    default: "",
});

export const chatAiAutoSendState = atom<boolean>({
    key: "chatai.autosend",
    default: false,
});

export const appBackgroundColorState = selector({
    key: "app.backgroundcolor",
    get: ({ get }) => {
        const array = get(chatAiHistoryState);
        let lastWithBackgroundColor;
        for (let i = array.length - 1; i >= 0; i--) {
            const kidResponse = getKidResponse(array[i].response);
            if (kidResponse?.backgroundColor) {
                lastWithBackgroundColor = kidResponse.backgroundColor;
                break;
            }
        }
        return lastWithBackgroundColor;
    },
});

export const appTitleState = selector({
    key: "app.title",
    get: ({ get }) => {
        const array = get(chatAiHistoryState);
        let lastWithTitle;
        for (let i = array.length - 1; i >= 0; i--) {
            const kidResponse = getKidResponse(array[i].response);
            if (kidResponse?.title) {
                lastWithTitle = kidResponse.title;
                break;
            }
        }
        return lastWithTitle;
    },
});

export const storyImageState = selector({
    key: "story.image",
    get: ({ get }) => {
        const array = get(chatAiHistoryState);
        let lastWithImage;
        for (let i = array.length - 1; i >= 0; i--) {
            if (array[i].imageUrl) {
                lastWithImage = array[i].imageUrl;
                break;
            }
        }
        return lastWithImage;
    },
});

export const appAudioSrcHistoryState = atom<string[]>({
    key: "app.audiosrc.history",
    default: [],
});

export const appAudioSrcPlayingState = atom<string[]>({
    key: "app.audiosrc.playing",
    default: [],
});

export const appAudioOnState = atom<boolean>({
    key: "app.audio.on",
    default: true,
});

export const appBackToTopCounterState = atom({
   key: "app.backtotop.counter",
   default: 0,
});

export const appShareObjectState = atom<ShareObject | undefined>({
        key: "app.share.object",
        default: undefined
    },
);

export const appShowSavedStoriesState = atom<boolean>({
    key: "app.show.saved.stores",
    default: false,
});

export const appSavedStoriesState = atomWithPersistence("app.saved.stories", [] as ShareObject[]);

export const appIsScrollingState = atom({
    key: "app.scrolling",
    default: false,
});
import { useStyletron } from 'baseui';
import { Modal, ROLE } from 'baseui/modal';
import { useTheme } from "../../hooks/useTheme";

type Props = {
    children: React.ReactNode;
    isOpen?: boolean;
    onClose: () => void;
    customSize?: number;
    closeable?: boolean;
    animate?: boolean;
}

export function StyledModal({ children, isOpen = true, onClose, customSize, closeable = true, animate = true }: Props) {
    const { theme } = useTheme();

    const defaultWidth = 500;

    return (
        <Modal
            closeable={closeable}
            isOpen={isOpen}
            role={ROLE.dialog}
            onClose={onClose}
            animate={animate}
            overrides={{
                Dialog: {
                    style: {
                        width: '80%',
                        height: '100%',
                        [theme.mediaQuery.medium]: {
                            width: customSize ? customSize + "px" : defaultWidth,
                        },
                        backgroundColor: theme.colors.primaryB,
                        border: 'solid 1px ' + theme.colors.contentTertiary,
                    }
                },
                DialogContainer: {
                    style: {
                        position: 'fixed',
                    },
                },
            }}
        >
            {children}
        </Modal>
    );
}

import { QueryResponse } from "../types/chatai";
import { useRecoilValue } from "recoil";
import { appAudioSrcHistoryState, appAudioSrcPlayingState } from "../states/chatai";

export function useAudioPlaying() {

    const audioSrcHistory = useRecoilValue(appAudioSrcHistoryState);
    const audioSrcPlaying = useRecoilValue(appAudioSrcPlayingState);

    const audioPlaying = (query: QueryResponse, playImagePrompt?: boolean) =>
        query.audioUrl
        && !playImagePrompt
        && !audioSrcHistory.includes(query.audioUrl)
        && (audioSrcPlaying.length === 0 || audioSrcPlaying.includes(query.audioUrl));

    return { audioPlaying };
}
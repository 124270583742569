import { LabelLarge } from "baseui/typography";
import { Button } from "baseui/button";
import React, { useState } from "react";
import { Audio } from "../ui/Audio";
import { useAudioPlaying } from "../../hooks/useAudioPlaying";
import { QueryResponse } from "../../types/chatai";
import { useAudioTranscription } from "../../hooks/useAudioTranscription";
import { useRecoilValue } from "recoil";
import { appIsScrollingState } from "../../states/chatai";
import { BarsAnim, VolumeIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { textShadow } from "../../utils/styling";

type Props = {
    query: QueryResponse;
    text: string;
    onClick: (text: string) => void;
    audioUrl?: string
}

export function ActionButton({ query, text, onClick, audioUrl }: Props) {
    const { theme } = useTheme();
    const [selected, setSelected] = useState(false);
    const [playAudio, setPlayAudio] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const { audioPlaying } = useAudioPlaying();
    const { isFetching } = useAudioTranscription();
    const isScrolling = useRecoilValue(appIsScrollingState);

    if (!text) {
        return null;
    }

    const fontSize = text.length > 10 ? 25 : 38;

    const onMouseEnter = () => {
        if (isScrolling || audioPlaying(query)) {
            return;
        }
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const timeout = setTimeout(() => {
            setPlayAudio(true);
            clearTimeout(timeout);
        }, 600);
        setTimeoutId(timeout);
    };

    const onMouseLeave = () => {
        setPlayAudio(false);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }

    return (
        <HFlex alignCenter style={{ width: undefined, marginLeft: "8px", marginRight: "8px" }}>
            {playAudio
                ? <Button kind={"tertiary"} onClick={() => setPlayAudio(false)} size={"mini"}>
                    <BarsAnim size={24} color={theme.colors.contentPrimary}/>
                </Button>
                : <Button kind={"tertiary"} onClick={() => setPlayAudio(true)} size={"mini"}>
                    <VolumeIcon size={24} color={theme.colors.contentPrimary}/>
                </Button>
            }
            <Button size={"large"}
                    kind={selected ? "secondary" : "tertiary"}
                    onMouseEnter={() => onMouseEnter()}
                    onMouseLeave={() => onMouseLeave()}
                    overrides={{
                        BaseButton: {
                            style: {
                                backgroundColor: selected ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.2)",
                                borderTopWidth: "2px",
                                borderBottomWidth: "2px",
                                borderLeftWidth: "2px",
                                borderRightWidth: "2px",
                                borderTopStyle: "solid",
                                borderBottomStyle: "solid",
                                borderLeftStyle: "solid",
                                borderRightStyle: "solid",
                                borderColor: "white",
                                fontWeight: "bold",
                                ...textShadow(2),
                            }
                        }
                    }}
                    onClick={() => {
                        onClick(text);
                        setSelected(true);
                    }}>
                <LabelLarge style={{ fontSize: fontSize + "px" }}>
                    {text}
                </LabelLarge>
                {playAudio && !isFetching && audioUrl && <Audio play src={audioUrl} onEnded={() => setPlayAudio(false)}/>}
            </Button>
        </HFlex>
    );
}



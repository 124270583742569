import { useRecoilValue, useSetRecoilState } from "recoil";
import { chatAiHistoryState } from "../states/chatai";
import { currentLanguageState } from "../states/localeState";
import { getKidResponse, KidResponse, QueryResponse } from "../types/chatai";
import { imageGeneration, streamedTtsUrl, textToSpeech, TextToSpeechRequest, Voice } from "../services/api";
import { removeEmojis } from "../utils/app";
import {
    statAudioTextToSpeechCharCounterState,
    statImageGenCounterState,
    statImageSecondsCounterState
} from "../states/statsState";

export function useTextToSpeech() {
    const setQueries = useSetRecoilState(chatAiHistoryState);
    const browserLang = useRecoilValue(currentLanguageState);
    const setTtsCharCounter = useSetRecoilState(statAudioTextToSpeechCharCounterState);
    const setImageSecondsCounter = useSetRecoilState(statImageSecondsCounterState);
    const setImageGenCounter = useSetRecoilState(statImageGenCounterState);

    const updateQuery = (query: QueryResponse, fields: any) => {
        setQueries(queries => queries
            .map(q => q.id === query.id ? {
                ...q,
                ...fields,
            } as QueryResponse : q));
    }

    const ttsForQuery = (query: QueryResponse) => {
        ttsForField(query, "text", "audioUrl", "female");
        ttsForField(query, "action1", "action1AudioUrl", "male");
        ttsForField(query, "action2", "action2AudioUrl", "male");
        ttsForField(query, "action3", "action3AudioUrl", "male");
        ttsForField(query, "action4", "action4AudioUrl", "male");
        loadImage(query);
    };

    const ttsForField = async (query: QueryResponse, field: keyof KidResponse, into: keyof QueryResponse, voice?: Voice) => {
        const kidResponse = getKidResponse(query.response);
        const lang = kidResponse?.locale || browserLang;
        if (kidResponse) {
            const value: string = removeEmojis(kidResponse[field] as string);
            if (value) {
                const ttsModel = new URLSearchParams(window.location.search).get('ttsmodel');
                const ttsRequest = { text: value, lang, voice, model: ttsModel || "google" } as TextToSpeechRequest;
                updateQuery(query, { [into]: await streamedTtsUrl(ttsRequest) });
            }
        }
    }

    const fetchTextToSpeech = async (request: TextToSpeechRequest) => {
        setTtsCharCounter(old => old + request.text.length);
        return textToSpeech({ text: removeEmojis(request.text), lang: request.lang, voice: request.voice });
    }

    function loadImage(query: QueryResponse) {
        const kidResponse = getKidResponse(query.response);
        if (kidResponse?.imagePrompt) {
            updateQuery(query, { imageLoading: true });
            setImageGenCounter(old => old + 1);
            const start = Date.now();
            imageGeneration(kidResponse.imagePrompt).then(response => {
                setImageSecondsCounter(old => old + ((Date.now() - start) / 1000));
                updateQuery(query, {
                    imageUrl: response.url,
                    imageUuid: response.uuid,
                    imageLoading: false,
                });
            });
        }
    }

    return { ttsForQuery, fetchTextToSpeech };
}
import { atom, selector } from "recoil";
import { getWindowSize } from "../utils/window";

export const windowSizeState = atom({
    key: "window.size",
    default: getWindowSize()
});

export const windowIsDesktopState = selector({
    key: "window.isdesktop",
    get: ({ get }) => {
        return !get(windowIsMobileState) && !get(windowIsTabletState);
    }
});

export const windowIsTabletState = selector({
    key: "window.istablet",
    get: ({ get }) => {
        return get(windowSizeState).innerWidth < 950 && !get(windowIsMobileState);
    }
});

export const windowIsMobileState = selector({
    key: "window.ismobile",
    get: ({ get }) => {
        return get(windowSizeState).innerWidth < 600;
    }
});

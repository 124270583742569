import { MicrophoneIcon } from "../ui/svg";
import { Button } from "baseui/button";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilValue } from "recoil";
import { appBackgroundColorState } from "../../states/chatai";
import { contrastFilterColor } from "../../utils/colors";
import { ButtonWithTts } from "../ui/ButtonWithTts";

export function AudioStartRecordingButton({ onClick }: { onClick: () => void }) {
    const { theme, strings } = useTheme();
    const backgroundColor = useRecoilValue(appBackgroundColorState);

    return (
        <ButtonWithTts tts={strings.Prompt.StartRecording} kind={"secondary"} onClick={onClick} shape={"circle"}>
            <MicrophoneIcon size={32} color={backgroundColor || theme.colors.contentPrimary} style={{
                filter: contrastFilterColor(backgroundColor),
            }}/>
        </ButtonWithTts>
    )
}
import { API_ENDPOINT, Endpoint, STATUS_OK, token } from "./api";
import { bytesFromContentAndString, sha256OfUint8Array } from "../utils/security";

export interface UploadFileRequest {
    file: File,
    extension: string;
    mediaType?: string;
    controller?: AbortController;
}

export interface UploadFileResponse {
    status: boolean;
    url?: string;
    text?: string;
}

export const uploadSaveHistory = async (req: UploadFileRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadsavehistory);
}

export const uploadAudioTranscription = async (req: UploadFileRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadaudiotranscription);
}

const upload = async (req: UploadFileRequest, endpoint: Endpoint): Promise<UploadFileResponse> => {
    const byteArray = await bytesFromContentAndString(req.file, token);
    const signature = await sha256OfUint8Array(byteArray);

    const data = new FormData();
    data.append('file', req.file);
    data.append('extension', req.extension);
    data.append('mediaType', req.mediaType || "");
    try {
        const response = await fetch(API_ENDPOINT + "/" + endpoint, {
            method: "post",
            body: data,
            signal: req.controller?.signal,
            headers: {
                "Signature": signature,
            }
        });
        const json = await response.json();
        return {
            status: json.status === STATUS_OK,
            text: json.text,
            url: json.url,
        }
    } catch {
        return {
            status: false,
        }
    }
}
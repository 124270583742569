import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { audioRecorderBlobState, audioRecorderMediaTypeState, audioRecordingState } from "../states/audioState";
import AudioRecorderPolyfill from 'audio-recorder-polyfill';

export type PermissionStatus = 'granted' | 'denied' | 'prompt';

export function useAudioRecorder(maxSeconds: number) {
    const [isRecording, setRecording] = useRecoilState(audioRecordingState);
    const isRecordingRef = useRef(isRecording);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const setRecordedBlob = useSetRecoilState(audioRecorderBlobState);
    const setRecorderMediaType = useSetRecoilState(audioRecorderMediaTypeState);
    const resetRecordedBlob = useResetRecoilState(audioRecorderBlobState);
    const [timerId, setTimerId] = useState<any>();
    const [permissionGranted, setPermissionGranted] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [silenceDetectionInterval, setSilenceDetectionInterval] = useState<any>(null);


    useEffect(() => {
        if (timedOut) {
            setTimedOut(false);
            stopRecording();
        }
    }, [timedOut])

    useEffect(() => {
        checkPermission().then(granted => setPermissionGranted(granted));
    }, []);

    useEffect(() => {
        const handlePermissionChange = (event: Event) => {
            const permissionStatus = (event.target as any).state as PermissionStatus;
            setPermissionGranted(permissionStatus === "granted");
        };

        (async () => {
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'microphone' } as any) as any;
                setPermissionGranted(permissionStatus === "granted");

                permissionStatus.addEventListener('change', handlePermissionChange);
                return () => {
                    permissionStatus.removeEventListener('change', handlePermissionChange);
                };
            } catch (error) {
                console.error('Failed to monitor audio recording permission status', error);
            }
        })();
    }, []);

    useEffect(() => {
        isRecordingRef.current = isRecording;
    }, [isRecording]);

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            window.MediaRecorder = AudioRecorderPolyfill;
        }

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const newMediaRecorder = new MediaRecorder(stream, { mimeType: isSafari ? 'audio/mpeg' : undefined });
                setMediaRecorder(newMediaRecorder);
                setStream(stream);
            });
        } else {
            console.error('getUserMedia API is not supported in this browser');
        }
    }, []);

    const startSilenceDetection = () => {
        // Create audio context and analyser
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(stream!);
        const analyser = audioContext.createAnalyser();
        source.connect(analyser);

        const buffer: number[] = [];
        let count = 0;

        const detectSilence = () => {
            const data = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(data);
            const average = data.reduce((a, b) => a + b) / data.length;

            buffer.push(average);

            const rollingAverage = buffer.reduce((a, b) => a + b) / buffer.length;
            count += 1;

            if (count === 10) {
                if (rollingAverage < 0.2) {
                    setTimedOut(true);
                }
                buffer.length = 0;
                count = 0;
            }
        };

        // Set interval to periodically check for silence
        const interval = setInterval(detectSilence, 100); // 100ms interval
        setSilenceDetectionInterval(interval);
    };

    useEffect(() => {
        if (mediaRecorder) {
            const handleDataAvailable = async (event: BlobEvent) => {
                if (event.data && event.data.size > 0) {
                    setRecorderMediaType(event.data.type);
                    setRecordedBlob(event.data);
                }
            };
            mediaRecorder.addEventListener('dataavailable', handleDataAvailable);
            return () => {
                stream?.getTracks().forEach(track => track.stop());
                mediaRecorder?.removeEventListener('dataavailable', handleDataAvailable);
                try {
                    mediaRecorder?.stop();
                } catch (exc) {
                    console.error("stopping recording on cleanup", exc);
                }
            };
        }
    }, [mediaRecorder]);

    const startRecording = () => {
        clearTimeout(timerId);
        if (mediaRecorder && !isRecording) {
            resetRecordedBlob()
            try {
                mediaRecorder.start();
            } catch (exc) {
                console.error("starting recording", exc);
            }
            setRecording(true);
            startSilenceDetection();
            setTimerId(setTimeout(() => {
                if (isRecordingRef.current) {
                    setTimedOut(true);
                }
                setTimerId(undefined);
            }, maxSeconds * 1000));
        }
    };

    const stopRecording = () => {
        clearTimeout(timerId);
        clearInterval(silenceDetectionInterval);
        setTimerId(undefined);
        if (mediaRecorder && isRecording) {
            try {
                mediaRecorder.stop();
            } catch (exc) {
                console.error("stopping recording", exc);
            }
        }
        setRecording(false);
    };

    const checkPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            stream.getTracks().forEach(track => track.stop()); // stop the stream
            return true;
        } catch (error) {
            console.error('Failed to get audio recording permission', error);
            return false;
        }
    }

    const ready = permissionGranted && mediaRecorder;

    return { isRecording, startRecording, stopRecording, ready };
}
import { MicrophoneIcon } from "../ui/svg";
import { ButtonTimed } from "baseui/button-timed";
import React from "react";
import { MAX_SECONDS } from "./AudioRecording";

export function AudioStopRecordingButton({ onClick }: { onClick: () => void }) {

    return (
        <ButtonTimed initialTime={MAX_SECONDS} onClick={onClick}>
            <MicrophoneIcon size={24} color={"#FF0000"}/>
        </ButtonTimed>
    )
}
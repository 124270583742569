import { Player } from "@lottiefiles/react-lottie-player";

export function Launch({ onAnimationEnd }: { onAnimationEnd: () => void }) {
    const size = 128;

    return (
        <Player src={"/anim/launch.json"} onStateChange={(state) => {
            if (state === "stopped") {
                onAnimationEnd();
            }
        }
        } autoplay style={{
            width: size + "px",
            height: size + "px",
        }}/>
    );
}
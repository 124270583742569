import React from "react";

export function Image({ src, size }: { src: string, size: number }) {

    return (
        <div style={{
            maxWidth: size + "px",
            maxHeight: size + "px",
            margin: "0 auto",
            overflow: 'hidden',
        }}>
            <img src={src} style={{
                maxWidth: "100%",
                maxHeight: "100%",
                height: "auto",
                objectFit: 'cover'
            }}/>
        </div>
    )
}
import { Button } from "baseui/button";
import { ReactNode, useEffect, useState } from "react";
import { Audio } from "./Audio";
import { useRecoilValue } from "recoil";
import { appIsScrollingState } from "../../states/chatai";
import { StatefulTooltip } from "baseui/tooltip";
import { useTextToSpeech } from "../../hooks/useTextToSpeech";

type Props = {
    kind: "primary" | "secondary" | "tertiary",
    onClick: () => void,
    tts: string | undefined,
    shape?: "circle" | undefined,
    style?: any,
    children?: ReactNode[] | ReactNode
}

export function ButtonWithTts({ kind, onClick, shape, style, children, tts }: Props) {
    const [audioUrl, setAudioUrl] = useState("");
    const [playAudio, setPlayAudio] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [firstMouseEntered, setFirstMouseEntered] = useState(false);
    const isScrolling = useRecoilValue(appIsScrollingState);
    const { fetchTextToSpeech } = useTextToSpeech();

    const onMouseEnter = () => {
        if (isScrolling) {
            return;
        }
        if (!firstMouseEntered) {
            setFirstMouseEntered(true);
        }
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const timeout = setTimeout(() => {
            setPlayAudio(true);
            clearTimeout(timeout);
        }, 600);
        setTimeoutId(timeout);
    };

    const onMouseLeave = () => {
        setPlayAudio(false);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    };

    useEffect(() => {
        if (tts && firstMouseEntered && !audioUrl) {
            fetchTextToSpeech({ text: tts, voice: "female" }).then(res => {
                if (res.status && res.url) {
                    setAudioUrl(res.url);
                }
            });
        }
    }, [tts, firstMouseEntered])

    return (
        <StatefulTooltip content={tts} showArrow>
            <Button size={"large"}
                    kind={kind}
                    shape={shape}
                    onClick={onClick}
                    style={style}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
            >
                {children}
                {audioUrl && playAudio && <Audio play src={audioUrl}/>}
            </Button>
        </StatefulTooltip>
    );
}
import { useRecoilState, useSetRecoilState } from "recoil";
import { audioTranscriptionFetchingIdsState, audioTranscriptionTextState } from "../states/audioState";
import { uploadAudioTranscription } from "../services/upload";
import { randomNumber } from "../utils/app";
import { statAudioSpeechToTextCharCounterState } from "../states/statsState";

export const useAudioTranscription = () => {
    const [ids, setIds] = useRecoilState(audioTranscriptionFetchingIdsState);
    const [text, setText] = useRecoilState(audioTranscriptionTextState);
    const setCounter = useSetRecoilState(statAudioSpeechToTextCharCounterState);

    const getAudioFileExtension = (mediaType: string) => {
        switch (mediaType) {
            case 'audio/webm;codecs=opus':
                return '.webm';
            case 'audio/mp4':
                return '.m4a';
            case 'audio/ogg':
                return '.ogg';
            case 'audio/wav':
                return '.wav';
            default:
                return "";
        }
    }

    const fetchTranscriptions = async (audioBlob: Blob, mediaType: string) => {
        const id = randomNumber();
        setIds(old => [...old, id]);
        const extension = getAudioFileExtension(mediaType);
        const file = new File([audioBlob], "audio" + extension);
        const response = await uploadAudioTranscription({
            file,
            extension,
            mediaType,
        });
        const text = response.text || "";
        setText(text);
        setIds(old => old.filter(oldId => oldId !== id));
        setCounter(old => old + text.length)
        return text;
    };

    const isFetching = ids.length > 0;

    return { fetchTranscriptions, isFetching, text };
};
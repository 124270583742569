import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Children, MutableRefObject, useRef } from "react";
import { useElementWidth } from "../../hooks/useElementWidth";

type Props = {
    children: React.ReactNode;
    columnGap?: number;
    rowGap?: number;
    baseWidth?: number;
    maxFullWidth?: string;
};

export function FlexGridLayout({ children, columnGap = 8, rowGap = 8, baseWidth = 220, maxFullWidth }: Props) {
    const childrenArray = Children.toArray(children);
    const contentWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    const { elementWidth } = useElementWidth(contentWrapperRef);
    const maxItems = Math.floor(elementWidth / baseWidth);
    const expandItems = (baseWidth * childrenArray.length) < elementWidth;

    const childrenWithGridItem = childrenArray.map((child, index) => (
        <FlexGridItem key={index} overrides={{
            Block: {
                style: {
                    width: `calc(${100 / maxItems}% - ${Math.ceil((columnGap * (maxItems - 1)) / maxItems)}px)`,
                    flexGrow: +expandItems,
                }
            }
        }}>{child}</FlexGridItem>
    ));

    return (
        <FlexGrid
            overrides={{
                Block: {
                    style: {
                        rowGap: `${rowGap}px`,
                        columnGap: `${columnGap}px`,
                        maxWidth: maxFullWidth,
                    }
                }
            }}
            ref={contentWrapperRef}
        >
            {childrenWithGridItem}
        </FlexGrid>
    );
}

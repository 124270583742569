import React, { ReactNode, useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { textShadow } from "../../utils/styling";
import { CloseIcon, EyeImageIcon } from "../ui/svg";
import { Button } from "baseui/button";

type Props = {
    incoming: boolean;
    imageUrl?: string;
    onRemoveClick?: () => void;
    children: ReactNode | ReactNode[];
    queryTitle?: string | undefined;
}

export function MessageBubble({ incoming, imageUrl, children, onRemoveClick, queryTitle }: Props) {
    const { theme } = useTheme();
    const [eyeHovering, setEyeHovering] = useState(false);
    const [eyeClicked, setEyeClicked] = useState(false);

    const showImage = eyeHovering || eyeClicked;
    const overlay = showImage ? "" : "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ";
    const borderRadius = "16px";

    return (
        <HFlex style={{ position: "relative" }}>
            {incoming && <div style={{
                position: "absolute",
                top: "50%",
                left: "34px",
                transform: "translateX(-50%) translateY(-50%) rotate(-90deg)",
                whiteSpace: "nowrap",
                color: theme.colors.contentPrimary,
                flexShrink: 0,
                fontFamily: "ArchitectsDaughter",
                fontSize: "17px",
                fontWeight: "bold",
                ...textShadow(2),
            }}>{queryTitle}</div>}
            <div style={{
                position: "relative",
                color: theme.colors.contentPrimary,
                marginBottom: "16px",
                marginLeft: incoming ? "48px" : "0px",
                marginRight: incoming ? "0px" : "48px",
                borderTopLeftRadius: incoming ? borderRadius : "0px",
                borderTopRightRadius: !incoming ? borderRadius : "0px",
                borderBottomLeftRadius: incoming ? borderRadius : "0px",
                borderBottomRightRadius: !incoming ? borderRadius : "0px",
                padding: "24px",
                maxWidth: "100%",
                minHeight: imageUrl ? "500px" : undefined,
                alignSelf: !incoming ? "start" : "end",
                backgroundColor: "darkolivegreen",
                backgroundImage: incoming ? "url('/images/bubble-bg.jpg')" : undefined,
                backgroundSize: "cover",
                borderTopWidth: "2px",
                borderBottomWidth: "2px",
                borderLeftWidth: "2px",
                borderRightWidth: "2px",
                borderColor: "white",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                overflow: "hidden",
                flexGrow: incoming ? 1 : undefined,
            }}>
                <div style={{
                    content: '',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: imageUrl ? overlay + `url(${imageUrl})` : "",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    opacity: imageUrl ? 1 : 0,
                    transition: "opacity 3s ease-in-out",
                }}/>
                <div style={{ position: "relative", opacity: showImage ? 0 : 1 }}>
                    {children}
                </div>
                {incoming && imageUrl && (
                    <Button kind={"tertiary"} size={"mini"}
                        onClick={() => {
                            setEyeClicked(true);
                            setTimeout(() => {
                                setEyeClicked(false);
                            }, 3_500);
                        }}
                        onMouseEnter={() => setEyeHovering(true)}
                        onMouseLeave={() => setEyeHovering(false)}
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "32px",
                            height: "32px",
                            zIndex: 1,
                            cursor: "pointer",
                        }}
                    >
                        <EyeImageIcon size={32} color={theme.colors.contentPrimary}/>
                    </Button>
                )}
                {incoming && (
                    <Button kind={"tertiary"}
                            size={"mini"}
                            onClick={onRemoveClick}
                            style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                width: "32px",
                                height: "32px",
                                zIndex: 1,
                                cursor: "pointer",
                            }}
                    >
                        <CloseIcon size={32} color={theme.colors.contentPrimary}/>
                    </Button>
                )}
            </div>
        </HFlex>
    );
}

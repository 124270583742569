export const textShadow = (strength: number, opacity: number = 1) => {

    return (
        {
            textShadow: `${strength}px ${strength}px ${strength * 2}px rgba(0, 0, 0, ${opacity}), ` +
                `-${strength}px -${strength}px ${strength * 2}px rgba(0, 0, 0, ${opacity}), ` +
                `${strength}px -${strength}px ${strength * 2}px rgba(0, 0, 0, ${opacity}), ` +
                `-${strength}px ${strength}px ${strength * 2}px rgba(0, 0, 0, ${opacity})`
        }
    );
}
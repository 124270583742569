import { getKidResponse, QueryResponse } from "../../types/chatai";
import { ActionButton } from "./ActionButton";
import { LinearFlex } from "../containers/LinearFlex";
import { useRecoilValue } from "recoil";
import { windowIsDesktopState } from "../../states/window";

export function ActionBar({
                              query,
                              onClick
                          }: { query: QueryResponse | undefined, onClick: (text: string) => void }) {
    const isDesktop = useRecoilValue(windowIsDesktopState);
    const kidResponse = query && getKidResponse(query.response)

    if (!kidResponse) {
        return null;
    }

    return (
        <LinearFlex orientation={isDesktop ? "horizontal" : "vertical"} alignCenter spacing
                    style={{ paddingTop: "16px" }}>
            <ActionButton query={query} text={kidResponse.action1} audioUrl={query?.action1AudioUrl} onClick={onClick}/>
            <ActionButton query={query} text={kidResponse.action2} audioUrl={query?.action2AudioUrl} onClick={onClick}/>
            <ActionButton query={query} text={kidResponse.action3} audioUrl={query?.action3AudioUrl} onClick={onClick}/>
            <ActionButton query={query} text={kidResponse.action4} audioUrl={query?.action4AudioUrl} onClick={onClick}/>
        </LinearFlex>
    );
}
const EN_TRANSLATION = 0;
const ES_TRANSLATION = 1;
const DE_TRANSLATION = 2;
const FR_TRANSLATION = 3;
const HE_TRANSLATION = 4;

export type LangFields = [string, string, string, string, string];

export interface IStrings {
    Prompt: {
        Placeholder: LangFields,
        StartWriting: LangFields,
        StartRecording: LangFields,
    },
    Suggestions: {
        Astronauts: LangFields,
        Castles: LangFields,
        Cats: LangFields,
        Jungle: LangFields,
        Math: LangFields,
        Pokemon: LangFields,
        Robots: LangFields,
        SportsCars: LangFields,
        Unicorns: LangFields,
    },
    Options: {
        ContinueStory: LangFields,
        NewStory: LangFields,
        ClearStory: LangFields,
        ShowSavedStories: LangFields,
        SaveShareStory: LangFields,
        On: LangFields,
        Off: LangFields,
        MuteSpeechVolume: LangFields,
        TurnOnSpeechVolume: LangFields,
        ScrollToTop: LangFields,
        SavedStories: LangFields,
        Yes: LangFields,
        ShareStory: LangFields,
        ShareWith: LangFields,
        UrlCopiedToClipboard: LangFields,
        SavingAndSharingLinks: LangFields,
    }
}

export const TRANSLATIONS: IStrings = {
    Prompt: {
        Placeholder: [
            "Choose emoji, click mic or enter text.",
            "Elige emoji, haz clic en el micrófono o introduce texto.",
            "Emoji auswählen, auf das Mikrofon klicken oder Text eingeben.",
            "Choisissez un emoji, cliquez sur le micro ou saisissez du texte.",
            "בחר אמוג'י, לחץ על המיקרופון או הקלד טקסט."
        ],
        StartWriting: [
            "Start writing",
            "Empieza a escribir",
            "Starten Sie zu schreiben",
            "Commencez à écrire",
            "התחל לכתוב"
        ],
        StartRecording: [
            "Start recording",
            "Comienza a grabar",
            "Starten Sie die Aufnahme",
            "Commencez à enregistrer",
            "התחל להקליט"
        ],
    },
    Suggestions: {
        Astronauts: [
            "Astronauts",
            "Astronautas",
            "Astronauten",
            "Astronautes",
            "אסטרונאוטים"
        ],
        Castles: [
            "Castles",
            "Castillos",
            "Burgen",
            "Châteaux",
            "ארמונות"
        ],
        Cats: [
            "Cats",
            "Gatos",
            "Katzen",
            "Chats",
            "חתולים"
        ],
        Jungle: [
            "Jungle",
            "Selva",
            "Dschungel",
            "Jungle",
            "ג'ונגל"
        ],
        Math: [
            "Math",
            "Matemática",
            "Mathematik",
            "Mathématiques",
            "מתמטיקה"
        ],
        Pokemon: [
            "Pokémon",
            "Pokémon",
            "Pokémon",
            "Pokémon",
            "פוקימון"
        ],
        Robots: [
            "Robots",
            "Robots",
            "Roboter",
            "Robots",
            "רובוטים"
        ],
        SportsCars: [
            "Sports cars",
            "Coches rápidos",
            "Rennautos",
            "Voitures de sport",
            "מכוניות ספורט"
        ],
        Unicorns: [
            "Unicorns",
            "Unicornios",
            "Einhorn",
            "Licorne",
            "יחידות קרן"
        ],
    },
    Options: {
        ContinueStory: [
            "No, continue this story",
            "No, continúa esta historia",
            "Nein, diese Geschichte fortsetzen",
            "Non, continuez cette histoire",
            "לא, המשך בסיפור הזה"
        ],
        NewStory: [
            "New Story",
            "Nueva Historia",
            "Neue Geschichte",
            "Nouvelle Histoire",
            "סיפור חדש",
        ],
        ClearStory: [
            "Clear this story and start from scratch?",
            "¿Borrar esta historia y empezar de cero?",
            "Diese Geschichte löschen und von vorne beginnen?",
            "Effacer cette histoire et recommencer à zéro?",
            "למחוק את הסיפור הזה ולהתחיל מאפס?"
        ],
        ShowSavedStories: [
            "Show saved stories",
            "Mostrar historias guardadas",
            "Gespeicherte Geschichten anzeigen",
            "Afficher les histoires enregistrées",
            "הצג סיפורים שמורים"
        ],
        SaveShareStory: [
            "Save and share Story",
            "Guardar y compartir historia",
            "Geschichte speichern und teilen",
            "Enregistrez et partagez l'histoire",
            "שמור ושתף סיפור"
        ],
        On: [
            "ON",
            "ENCE",
            "EIN",
            "SUR",
            "דלוק"
        ],
        Off: [
            "OFF",
            "APAG",
            "AUS",
            "ÉTEINT",
            "כבוי"
        ],
        MuteSpeechVolume: [
            "Mute speech volume",
            "Silenciar volumen de voz",
            "Sprachlautstärke stummschalten",
            "Mettre le volume de la parole en sourdine",
            "השתק את עוצמת הקול"
        ],
        TurnOnSpeechVolume: [
            "Turn on speech volume",
            "Encender el volumen de voz",
            "Sprachlautstärke einschalten",
            "Activer le volume de la parole",
            "הפעל את עוצמת הקול"
        ],
        ScrollToTop: [
            "Scroll to top",
            "Desplazarse al inicio",
            "Zum Anfang scrollen",
            "Faire défiler vers le haut",
            "גלול לראש העמוד"
        ],
        SavedStories: [
            "Saved Stories",
            "Historias Guardadas",
            "Gespeicherte Geschichten",
            "Histoires Sauvegardées",
            "סיפורים שמורים"
        ],
        Yes: [
            "Yes",
            "Sí",
            "Ja",
            "Oui",
            "כן"
        ],
        ShareStory: [
            "Share Story",
            "Compartir Historia",
            "Geschichte teilen",
            "Partager l'histoire",
            "שתף סיפור"
        ],
        ShareWith: [
            "Share With",
            "Compartir Con",
            "Teilen Mit",
            "Partager Avec",
            "שתף עם"
        ],
        UrlCopiedToClipboard: [
            "URL Copied to Clipboard",
            "URL Copiado al Portapapeles",
            "URL in Zwischenablage kopiert",
            "URL Copié dans le Presse-papiers",
            "הכתובת הועתקה ללוח"
        ],
        SavingAndSharingLinks: [
            "Saving and Sharing Links",
            "Guardando y Compartiendo Enlaces",
            "Links Speichern und Teilen",
            "Enregistrement et Partage de Liens",
            "שמירה ושיתוף של קישורים"
        ],
    }
};


const TRANSLATION_ENTRIES = Object.entries(TRANSLATIONS) as [keyof typeof TRANSLATIONS, typeof TRANSLATIONS][];

const getTranslations = (index: number): { [k in keyof IStrings]: Record<keyof IStrings[k], string> } => {
    const entries = TRANSLATION_ENTRIES.map(([key, fields]) => {
        const FIELD_ENTRIES = Object.entries(fields);
        const values = FIELD_ENTRIES.map(([key2, value]) => [key2, value[index]]);
        return [key, Object.fromEntries(values)];
    });
    return Object.fromEntries(entries);
};

export const strings = {
    en: getTranslations(EN_TRANSLATION),
    es: getTranslations(ES_TRANSLATION),
    de: getTranslations(DE_TRANSLATION),
    fr: getTranslations(FR_TRANSLATION),
    he: getTranslations(HE_TRANSLATION),
};

import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { chatAiHistoryState } from "../../states/chatai";
import { restoreHistory } from "../../services/api";
import { QueryResponse } from "../../types/chatai";
import React, { useState } from "react";
import { Centered } from "../containers/Centered";
import { DogWaiting } from "../ui/DogWaiting";
import { ShareObject } from "../../types/share";

export function SharedChatAi() {
    const { uuid } = useParams();
    const setHistory = useSetRecoilState(chatAiHistoryState);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    if (uuid && !loading && !loaded) {
        setLoading(true);
        restoreHistory(uuid).then(res => {
            if (res.status) {
                setLoaded(true);
                const shareObject = JSON.parse(res.plaintext) as ShareObject;
                setHistory(shareObject.history);
                navigate("/");
            }
        })
    }

    return (
        <div style={{
            width: "100vw",
            height: "100vh",
        }}>
            <Centered>
                <DogWaiting/>
            </Centered>
        </div>
    );
}
import { Player } from "@lottiefiles/react-lottie-player";

export function DogWaiting() {
    const size = 48;

    return (
        <Player src={"/anim/dog.json"} autoplay loop style={{
            width: size + "px",
            height: size + "px",
        }}/>
    );
}
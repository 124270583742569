import { ButtonWithTts } from "../ui/ButtonWithTts";
import { ArrowUpIcon } from "../ui/svg";
import { VFlex } from "../containers/VFlex";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { appBackgroundColorState, appBackToTopCounterState } from "../../states/chatai";
import { windowIsDesktopState } from "../../states/window";
import { luminance } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";

export function MenuRight() {
    const { strings } = useTheme();
    const backgroundColor = useRecoilValue(appBackgroundColorState);
    const isDesktop = useRecoilValue(windowIsDesktopState);
    const setBackToTopCounter = useSetRecoilState(appBackToTopCounterState);

    const onBackToTop = () => {
        setBackToTopCounter(old => old + 1);
    };

    const blendStyle = {} as any;
    const iconSize = isDesktop ? 32 : 24;
    const iconColor = backgroundColor && (luminance(backgroundColor) > 0.9) ? "green" : "white";

    return (
        <VFlex style={{
            position: "fixed", top: 0,
            right: 0
        }}>
            <ButtonWithTts
                kind={"tertiary"}
                onClick={onBackToTop}
                tts={strings.Options.ScrollToTop}
            >
                <ArrowUpIcon size={iconSize} color={iconColor} style={blendStyle}/>
            </ButtonWithTts>
        </VFlex>
    )
}
export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
}

export function getFileExtension(filename: string) {
    return filename.substring(filename.lastIndexOf('.'), filename.length) || filename;
}

export function randomString() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function randomNumber(excludedNumbers: number[] = []): number {
    const number = +Math.random().toString().substring(2).slice(0, 5);
    if (excludedNumbers.includes(number)) {
        return randomNumber(excludedNumbers);
    }
    return number;
}

export function isMac() {
    return navigator.userAgent.indexOf('Mac') !== -1;
}

export function removeEmojis(text: string | undefined) {
    return text?.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') || "";
}
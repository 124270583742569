import { atom, AtomEffect, RecoilState } from "recoil";
import { deleteDB, IDBPDatabase, openDB } from "idb";

export function atomWithPersistence<T>(key: string, val: T): RecoilState<T> {
    return atom({
        key: key,
        default: val,
        effects: [localStorageEffect],
    });
}

export function atomWithIndexedPersistence<T>(key: string, val: T): RecoilState<T> {
    return atom({
        key: key,
        default: val,
        effects: [idbStorageEffect],
    });
}

const DB = "ht-db";
const STORE = "ht-store";

let dbPromise: Promise<IDBPDatabase> | null = null;

const getDbPromise = () => {
    if (dbPromise !== null) {
        return dbPromise;
    }
    dbPromise = openDB(DB, 1, {
        upgrade(db) {
            db.createObjectStore(STORE);
        },
    });

    return dbPromise;
}

const getItemPromise = (key: string) => {
    return getDbPromise().then(db => db.get(STORE, key))
}

export async function deleteDb() {
    localStorage.clear();
    await deleteDB(DB);
    dbPromise = null;
}

export const localStorageEffect: AtomEffect<any> = ({ setSelf, onSet, node }) => {
    const savedValue = localStorage.getItem(node.key)
    if (savedValue !== null && savedValue !== 'undefined') {
        setSelf(JSON.parse(savedValue));
    }
    onSet((newValue, _, isReset) => {
        isReset
            ? localStorage.removeItem(node.key)
            : localStorage.setItem(node.key, JSON.stringify(newValue));
    });
};

export const idbStorageEffect: AtomEffect<any> = ({ setSelf, onSet, node, trigger }) => {
    const load = async () => {
        const savedValue = await getItemPromise(node.key);
        if (savedValue) {
            setSelf(savedValue);
        }
    };
    if (trigger === 'get') {
        load().catch(error => console.warn(error));
    }

    onSet(async (newValue, _, isReset) => {
        let db = await getDbPromise();
        isReset
            ? await db.delete(STORE, node.key)
            : await db.put(STORE, newValue, node.key);
    });
};
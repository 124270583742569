import { useRecoilState } from "recoil";
import { appSavedStoriesState, appShowSavedStoriesState } from "../../states/chatai";
import { StoryCard } from "./StoryCard";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { StyledModal } from "../ui/StylesModal";
import { ModalHeader } from "baseui/modal";
import { HFlex } from "../containers/HFlex";
import { Button } from "baseui/button";
import { TrashIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { SpacerExpand } from "../containers/Spacer";

export function SavedStoriesModal() {
    const { theme } = useTheme();
    const [savedStories, setSavedStories] = useRecoilState(appSavedStoriesState);
    const [show, setShow] = useRecoilState(appShowSavedStoriesState);

    if (!show) {
        return null;
    }

    return (
        <StyledModal
            onClose={() => setShow(false)}
            closeable
            isOpen
            animate
        >
            <ModalHeader>Saved Stories</ModalHeader>
            <HFlex>
                <SpacerExpand/>
                <div style={{
                    overflow: "auto",
                    width: "90%",
                    height: "500px",
                    paddingLeft: "18px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "18px",
                }}>
                    <FlexGridLayout baseWidth={250}>
                        {savedStories.map(story => (
                            <HFlex style={{
                                alignItems: "start",
                                paddingRight: "32px",
                                width: "290px",
                            }}>
                                <StoryCard object={story}/>
                                <Button size={"mini"} kind={"tertiary"}
                                        onClick={() => setSavedStories(old => old.filter(s => s.url !== story.url))}>
                                    <TrashIcon size={12} color={theme.colors.contentPrimary}/>
                                </Button>
                            </HFlex>
                        ))}
                    </FlexGridLayout>
                </div>
                <SpacerExpand/>
            </HFlex>
        </StyledModal>
    );
}
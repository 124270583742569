import { useRecoilValue } from "recoil";
import { format } from "date-fns";
import { windowIsMobileState } from "../states/window";

export function useTime() {
    const isMobile = useRecoilValue(windowIsMobileState);

    const getShortDateFormatPattern = () => {
        const formatter = new Intl.DateTimeFormat(navigator.language, {
            month: '2-digit',
            day: '2-digit',
        });
        const date = new Date(2000, 1, 3);
        const formattedDate = formatter.format(date);
        const formatPattern = formattedDate
            .replace('02', 'MM')
            .replace('03', 'dd');
        return formatPattern;
    }

    const getLongDateFormatPattern = () => {
        const formatter = new Intl.DateTimeFormat(navigator.language, {
            year: isMobile ? '2-digit' : 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        const date = new Date(2000, 1, 3);
        const formattedDate = formatter.format(date);
        const formatPattern = formattedDate
            .replace(isMobile ? '00' : '2000', isMobile ? 'yy' : 'yyyy')
            .replace('02', 'MM')
            .replace('03', 'dd');
        return formatPattern;
    }

    const dateFormat = getShortDateFormatPattern();
    const longFormDateFormat = getLongDateFormatPattern();

    const formatIsoDate = (date: Date) => {
        return format(date, "yyyy-MM-dd");
    }

    const formatDateAndTime = (timestamp: number): string => {
        const dateObject = new Date(timestamp);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' } as any;
        return new Intl.DateTimeFormat('en-US', options).format(dateObject);
    }

    return {
        formatDateAndTime,
        formatIsoDate,
        dateFormat,
        longFormDateFormat,
    }
}
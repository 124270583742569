import { SuggestionButton } from "./SuggestionButton";
import Marquee from "react-fast-marquee";
import React from "react";
import { useTheme } from "../../hooks/useTheme";

export function SuggestionMarquee() {
    const { strings } = useTheme();
    return (
        <Marquee speed={16} style={{ flexShrink: 0 }}>
            <SuggestionButton src={"/anim/astronaut.json"} text={strings.Suggestions.Astronauts}/>
            <SuggestionButton src={"/anim/castle.json"} text={strings.Suggestions.Castles}/>
            <SuggestionButton src={"/anim/cat.json"} text={strings.Suggestions.Cats}/>
            <SuggestionButton src={"/anim/batman.json"} text={"Batman"}/>
            <SuggestionButton src={"/anim/math.json"} text={strings.Suggestions.Math}/>
            <SuggestionButton src={"/anim/superman.json"} text={"Superman"}/>
            <SuggestionButton src={"/anim/pokemon.json"} text={strings.Suggestions.Pokemon}/>
            <SuggestionButton src={"/anim/robot.json"} text={strings.Suggestions.Robots}/>
            <SuggestionButton src={"/anim/sportscar.json"} text={strings.Suggestions.SportsCars}/>
            <SuggestionButton src={"/anim/unicorn.json"} text={strings.Suggestions.Unicorns}/>
        </Marquee>
    )
}
import React from 'react';
import './App.css';
import { BaseProvider, DarkTheme } from "baseui";
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { ChatAi } from "./components/chat/ChatAi";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SharedChatAi } from "./components/chat/SharedChatAi";

const engine = new Styletron();

function App() {
    const theme = DarkTheme;

    return (
        <RecoilRoot>
            <StyletronProvider value={engine}>
                <BaseProvider theme={theme}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/share/:uuid" element={<SharedChatAi/>}/>
                            <Route path="/*" element={<ChatAi/>}/>
                        </Routes>
                    </BrowserRouter>
                </BaseProvider>
            </StyletronProvider>
        </RecoilRoot>
    );
}

export default App;

import React from "react";

type IconProps = {
    color?: string;
    size?: number;
    text?: string;
    animated?: boolean;
    repeatCount?: number | "indefinite";
    duration?: string;
    style?: any;
};

export function CloseIcon({ color = '#444444' }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
        >
            <g transform="translate(0, 0)">
                <line
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="13.5"
                    y1="2.5"
                    x2="2.5"
                    y2="13.5"
                    data-cap="butt"
                ></line>
                <line
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="2.5"
                    y1="2.5"
                    x2="13.5"
                    y2="13.5"
                    data-cap="butt"
                ></line>
            </g>
        </svg>
    );
}


export function TrashIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={style} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path d="M27,10V28a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V10"></path>
                <line x1="16" y1="15" x2="16" y2="25"></line>
                <line x1="11" y1="15" x2="11" y2="25"></line>
                <line x1="21" y1="15" x2="21" y2="25"></line>
                <polyline points="11 6 11 1 21 1 21 6" strokeLinecap="butt" stroke={color}></polyline>
                <line x1="30" y1="6" x2="2" y2="6" stroke={color}></line>
            </g>
        </svg>
    );
}

export function CheckCircledIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <polyline points=" 9,17 13,21 23,11 " stroke={color}></polyline>
                <circle cx="16" cy="16" r="15"></circle>
            </g>
        </svg>
    );
}


export function BookmarkIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M28,30,16,23,4,30V4A3,3,0,0,1,7,1H25a3,3,0,0,1,3,3Z" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function SendIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="30" x2="14" y1="2" y2="18"/>
                <polygon fill="none" points="30 2 21 30 14 18 2 11 30 2" stroke={color}/>
            </g>
        </svg>
    );
}

export function MicrophoneIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M16,22c4.411,0,8-3.589,8-8V8c0-4.411-3.589-8-8-8S8,3.589,8,8v6C8,18.411,11.589,22,16,22z"
                      fill={color}/>
                <path
                    d="M24,30h-7v-4.051C23.149,25.438,28,20.28,28,14c0-0.553-0.448-1-1-1s-1,0.447-1,1 c0,5.514-4.486,10-10,10S6,19.514,6,14c0-0.553-0.448-1-1-1s-1,0.447-1,1c0,6.28,4.851,11.438,11,11.949V30H8c-0.552,0-1,0.447-1,1 s0.448,1,1,1h16c0.552,0,1-0.447,1-1S24.552,30,24,30z"/>
            </g>
        </svg>
    );
}

export function VolumeMuteIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="26,15 26,30 16.487,24.146 " stroke={color}/>
                <polyline fill="none" points="13,22 2,22 2,10 13,10 26,2 26,9 " stroke={color} strokeLinecap="butt"/>
                <line fill="none" x1="30" x2="6" y1="5" y2="29"/>
            </g>
        </svg>
    );
};

export function VolumeIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polygon fill="none" points="20,29 9,21 1,21 1,11 9,11 20,3 " stroke={color}/>
                <line fill="none" x1="27" x2="31" y1="16" y2="16"/>
                <line fill="none" x1="25.3" x2="28.7" y1="9.5" y2="7.5"/>
                <line fill="none" x1="25.3" x2="28.7" y1="22.5" y2="24.5"/>
            </g>
        </svg>
    );
}

export function ArrowUpIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="16" x2="16" y1="30" y2="2"/>
                <polyline fill="none" points="7,11 16,2 25,11 " stroke={color}/>
            </g>
        </svg>
    );
}


export function BarsAnim({ size, color }: IconProps) {
    const css = `.nc-loop-bars-32-icon-o *{--animation-duration:1.2s;transform-origin:16px 16px;animation:nc-loop-bars-anim var(--animation-duration) infinite}.nc-loop-bars-32-icon-o :nth-child(2){opacity:1;transform:scaleY(1)}.nc-loop-bars-32-icon-o :nth-child(1),.nc-loop-bars-32-icon-o :nth-child(3){opacity:.3;transform:scaleY(.5);animation-delay:calc(var(--animation-duration)/-2)}@keyframes nc-loop-bars-anim{0%,100%{opacity:1;transform:scaleY(1)}50%{opacity:.3;transform:scaleY(.5)}}`;

    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <g className="nc-loop-bars-32-icon-o">
                    <path d="M3 7v17" fill="none" stroke={color}/>
                    <path d="M16 7v17" fill="none"/>
                    <path d="M29 7v17" fill="none" stroke={color}/>
                </g>
                <style>{css}</style>
            </g>
        </svg>
    );
}

export function SocialSharing({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M27,21v6a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V10A3,3,0,0,1,5,7H9" fill="none" stroke={color}/>
                <path d="M30,12,20,3V8c-6.9.3-12,2.6-12,13,2.4-3.2,5.1-5.1,12-5v5Z" fill="none"/>
            </g>
        </svg>
    );
}

export function TextIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path
                    d="M26.5,1H5.5c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5H14V29c0,1.104,.896,2,2,2s2-.896,2-2V4h8.5c.829,0,1.5-.671,1.5-1.5s-.671-1.5-1.5-1.5Z"
                    fill={color}/>
            </g>
        </svg>
    );
}

export function EraserIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeWidth="2">
                <path
                    d="M9.586,30h2.828l18.293-18.293c0.391-0.391,0.391-1.023,0-1.414l-9-9c-0.391-0.391-1.023-0.391-1.414,0 l-19,19c-0.391,0.391-0.391,1.023,0,1.414L9.586,30z M12,12.414L19.586,20L11,28.586L3.414,21L12,12.414z"
                    fill={color}/>
                <path d="M29,31H3c-0.552,0-1-0.448-1-1s0.448-1,1-1h26c0.552,0,1,0.448,1,1S29.552,31,29,31z"/>
            </g>
        </svg>
    );
}

export function BookmarksIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M25,30,14,23,3,30V8A3,3,0,0,1,6,5H22a3,3,0,0,1,3,3Z" fill="none" stroke={color}/>
                <path d="M10,1H26a3,3,0,0,1,3,3V26" fill="none"/>
            </g>
        </svg>
    );
}


export function NewStoryIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path
                    d="M29,14h-11V3c0-.553-.448-1-1-1h-2c-.552,0-1,.447-1,1V14H3c-.552,0-1,.447-1,1v2c0,.553,.448,1,1,1H14v11c0,.553,.448,1,1,1h2c.552,0,1-.447,1-1v-11h11c.552,0,1-.447,1-1v-2c0-.553-.448-1-1-1Z"
                    fill={color}/>
            </g>
        </svg>
    );
}

export function EyeImageIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <polyline fill="none" points="2 8 2 2 8 2"/>
                <polyline fill="none" points="30 8 30 2 24 2"/>
                <polyline fill="none" points="2 24 2 30 8 30"/>
                <polyline fill="none" points="30 24 30 30 24 30"/>
                <path d="M2,16S8,8,16,8s14,8,14,8-6,8-14,8S2,16,2,16Z" fill="none" stroke={color}/>
                <circle cx="16" cy="16" fill="none" r="4"/>
            </g>
        </svg>
    );
}
import { useRecoilValue } from "recoil";
import { useTheme } from "../../hooks/useTheme";
import { windowIsMobileState } from "../../states/window";

type Props = {
    width?: string;
    height?: string;
};

export function Spacer({ width = 'default', height = 'default' }: Props) {
    const { theme } = useTheme();
    const isMobile = useRecoilValue(windowIsMobileState);

    const w = width === 'default'
        ? isMobile ? theme.sizing.scale100 : theme.sizing.scale300
        : width;
    const h = height === 'default'
        ? isMobile ? theme.sizing.scale100 : theme.sizing.scale300
        : height;

    return <div style={{ width: w, height: h, flexShrink: 0 }}/>;
}

export function SpacerExpand() {
    return <div style={{ flexGrow: 1, pointerEvents: "none", }}/>;
}

import { HFlex } from "../containers/HFlex";
import React, { useEffect, useState } from "react";
import { useAudioRecorder } from "../../hooks/useAudioRecorder";
import { useTheme } from "../../hooks/useTheme";
import { AudioStopRecordingButton } from "./AudioStopRecordingButton";
import { AudioStartRecordingButton } from "./AudioStartRecordingButton";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { audioRecorderBlobState, audioRecorderMediaTypeState } from "../../states/audioState";
import { useAudioTranscription } from "../../hooks/useAudioTranscription";
import { DogWaiting } from "../ui/DogWaiting";
import { LabelWithTts } from "../ui/LabelWithTts";
import { textShadow } from "../../utils/styling";

export const MAX_SECONDS = 20;

export function AudioRecording({onStartRecording}: {onStartRecording: () => void}) {
    const { theme } = useTheme();
    const { startRecording, stopRecording, ready, isRecording } = useAudioRecorder(MAX_SECONDS);
    const audioBlob = useRecoilValue(audioRecorderBlobState);
    const audioMediaType = useRecoilValue(audioRecorderMediaTypeState);
    const resetAudioBlob = useResetRecoilState(audioRecorderBlobState);
    const { fetchTranscriptions } = useAudioTranscription();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (audioBlob?.size > 0) {
            setLoading(true);
            fetchTranscriptions(audioBlob, audioMediaType)
                .finally(() => {
                    setLoading(false);
                    resetAudioBlob();
                });
        }
    }, [audioBlob]);

    const onStartRecordingClick = () => {
        onStartRecording();
        startRecording();
    };

    return (
        <HFlex style={{ overflow: "hidden" }}>
            {loading && <div><DogWaiting/></div>}
            {!loading && !ready &&
                <LabelWithTts style={textShadow(1)} tts={"Please allow using your mic for recording"}>{"🎙️ Please allow using your mic for recording 🎙️"}</LabelWithTts>}
            {!loading && ready && isRecording && <AudioStopRecordingButton onClick={stopRecording}/>}
            {!loading && ready && !isRecording && <AudioStartRecordingButton onClick={onStartRecordingClick}/>}
        </HFlex>
    );
}
import { StyleObject } from "styletron-react";
import { useTheme } from "../../hooks/useTheme";

type Props = {
    children: React.ReactNode;
    style?: StyleObject
};

export function Centered({ children, style }: Props) {
    const { css } = useTheme();

    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                ...style,
            })}>
            {children}
        </div>
    );
}
import { StyleObject } from "styletron-react";
import { VFlex } from "./VFlex";
import { HFlex } from "./HFlex";

type Props = {

    orientation: "vertical" | "horizontal";
    children: React.ReactNode;
    style?: StyleObject;
    expand?: boolean
    spacing?: string | true,
    onClick?: () => void;
    alignCenter?: boolean;
};

export function LinearFlex(props: Props) {
    if (props.orientation === "vertical") {
        return <VFlex {...props}></VFlex>
    } else {
        return <HFlex {...props}></HFlex>
    }
}
export async function hashAudioBlob(audioBlob: Blob): Promise<string> {
    const arrayBuffer = await audioBlob.arrayBuffer();
    const byteArray = new Uint8Array(arrayBuffer);
    const hash = await sha256OfUint8Array(byteArray);
    return hash;
}

export async function sha256OfUint8Array(data: Uint8Array): Promise<string> {
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export async function bytesFromContentAndString(file: File, additionalString: string): Promise<Uint8Array> {
    const fileReader = new FileReader();
    const filePromise = new Promise<Uint8Array>((resolve, reject) => {
        fileReader.onload = () => resolve(new Uint8Array(fileReader.result as ArrayBuffer));
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(file);
    });
    const byteArray = await filePromise;
    const additionalBytes = new TextEncoder().encode(additionalString);
    const concatenatedArray = new Uint8Array(byteArray.length + additionalBytes.length);
    concatenatedArray.set(byteArray, 0);
    concatenatedArray.set(additionalBytes, byteArray.length);
    return concatenatedArray;
}

export async function sha256(text: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
}
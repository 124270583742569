import { MessageBubble } from "./MessageBubble";
import ReactMarkdown from "react-markdown";
import { Audio } from "../ui/Audio";
import { Button } from "baseui/button";
import { ActionBar } from "./ActionBar";
import React, { useState } from "react";
import { getKidResponse, getKidText, QueryResponse } from "../../types/chatai";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    appAudioSrcHistoryState,
    appAudioSrcPlayingState,
    chatAiAutoSendState,
    chatAiHistoryState,
    chatAiIgnoreState,
    chatAiInputTextState
} from "../../states/chatai";
import { HFlex } from "../containers/HFlex";
import { BarsAnim, VolumeIcon } from "../ui/svg";
import { windowIsDesktopState, windowIsMobileState } from "../../states/window";
import { VFlex } from "../containers/VFlex";
import { DogWaiting } from "../ui/DogWaiting";
import { rtlLanguages } from "../../utils/constants";
import { textShadow } from "../../utils/styling";

export function QueryResponseEntry({ query, index }: { query: QueryResponse, index: number }) {
    const { theme } = useTheme();
    const setInputText = useSetRecoilState(chatAiInputTextState);
    const setAutoSend = useSetRecoilState(chatAiAutoSendState);
    const setAudioSrcHistory = useSetRecoilState(appAudioSrcHistoryState);
    const setAudioSrcPlaying = useSetRecoilState(appAudioSrcPlayingState);
    const [isAudioPlaying, setAudioPlaying] = useState(false);
    const isMobile = useRecoilValue(windowIsMobileState);
    const isDesktop = useRecoilValue(windowIsDesktopState);
    const setQueries = useSetRecoilState(chatAiHistoryState);
    const setIgnore = useSetRecoilState(chatAiIgnoreState);

    const onActionClick = (text: string) => {
        setAutoSend(true);
        setInputText(text);
    };

    const kidText = getKidText(query.response);


    const endAudio = () => {
        setAudioPlaying(false);
        setAudioSrcPlaying(old => old.filter(url => url !== query.audioUrl));
        setAudioSrcHistory(old => [...old, query.audioUrl || ""]);
    };

    const kidResponse = getKidResponse(query.response);

    const isRightToLeft = kidResponse?.locale && rtlLanguages.includes(kidResponse.locale);

    const onRemoveClick = () => {
        setIgnore(old => [query, ...old]);
    };

    return (
        <div key={index} style={{
            width: "100%",
        }}>
            {query.query && query.query !== "START_BUDDY" && (
                <MessageBubble incoming={false}>
                    {query.query}
                </MessageBubble>
            )}
            <MessageBubble incoming imageUrl={query.imageUrl} queryTitle={kidResponse?.title} onRemoveClick={onRemoveClick}>
                <VFlex style={{ minHeight: query.imageUrl ? "500px" : undefined, }}>
                    <HFlex spacing style={{
                        paddingTop: isMobile ? "0px" : "148px",
                    }}>
                        {kidText
                            ? <HFlex spacing style={{
                                paddingTop: isMobile ? "0px" : "148px",
                                paddingLeft: isMobile ? "0px" : isDesktop ? "148px" : "28px",
                                paddingRight: isMobile ? "0px" : isDesktop ? "148px" : "28px",
                                textAlign: kidText.length > 200 ? "justify" : undefined,
                                fontSize: isMobile ? "23uspx" : "27px",
                                fontWeight: "bold",
                                ...textShadow(2),
                                fontFamily: "LibreBaskerville-Regular",
                            }}>
                                {isAudioPlaying
                                    ? <Button kind={"tertiary"} onClick={() => setAudioPlaying(false)} size={"mini"}>
                                        <BarsAnim size={24} color={theme.colors.contentPrimary}/>
                                    </Button>
                                    : <Button kind={"tertiary"} onClick={() => setAudioPlaying(true)} size={"mini"}>
                                        <VolumeIcon size={24} color={theme.colors.contentPrimary}/>
                                    </Button>
                                }
                                {isAudioPlaying && <Audio play={isAudioPlaying} src={query.audioUrl!}
                                        onStarted={() => setAudioSrcPlaying(old => [...old, query.audioUrl || ""])}
                                        onEnded={endAudio}
                                />}
                                {isRightToLeft &&
                                    <style>
                                        {"div.markdown { direction: rtl }"}
                                    </style>
                                }
                                <ReactMarkdown className={"markdown"}>
                                    {kidText}
                                </ReactMarkdown>
                            </HFlex>
                            : <DogWaiting/>}
                    </HFlex>
                    <ActionBar query={query} onClick={onActionClick}/>
                </VFlex>
            </MessageBubble>
        </div>
    )
}
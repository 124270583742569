import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { honeypotState, mouseMovedState, startTimeState } from "../states/notARobotState";

export function useNotARobot() {
    const [mouseMoved, setMouseMoved] = useRecoilState(mouseMovedState);
    const [honeypot, setHoneypot] = useRecoilState(honeypotState);
    const [startTime, setStartTime] = useRecoilState(startTimeState);

    useEffect(() => {
        setStartTime(Date.now());
        const handleMouseMove = () => {
            setMouseMoved(true);
            document.removeEventListener('mousemove', handleMouseMove);
        }
        document.addEventListener('mousemove', handleMouseMove);
    }, []);

    const isRobot = () => {
        const currentTime = Date.now();
        if (honeypot || currentTime - startTime < 500 || !mouseMoved) {
            alert('Are you a robot?');
            return true;
        }
        return false;
    }

    return { isRobot };
}
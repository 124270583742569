export type QueryResponse = {
    query: string;
    response: string;
    id: number;
    audioUrl?: string;
    audioPlayed?: 0;
    imageUrl?: string;
    imageUuid?: string;
    imageLoading?: boolean;
    imagePromptUrl?: string;
    action1AudioUrl?: string;
    action2AudioUrl?: string;
    action3AudioUrl?: string;
    action4AudioUrl?: string;
}

export interface KidResponse {
    text: string;
    action1: string;
    action2: string;
    action3: string;
    action4: string;
    imagePrompt?: string;
    title?: string;
    backgroundColor?: string;
    locale?: string;
}

export function getKidResponse(str: string): KidResponse | undefined {
    try {
        return JSON.parse(str) as KidResponse;
    } catch (e) {
        return undefined;
    }
}

export const getKidText = (response: string) => {
    const kidResponse = getKidResponse(response);
    if (kidResponse) {
        return kidResponse.text;
    }
    const kidResponseTag = '"text": "';
    const startIdx = response.indexOf(kidResponseTag, 0);
    const endIdx = response.indexOf('"', startIdx + kidResponseTag.length);
    const kidText = startIdx > 0
        ? response.substring(startIdx + kidResponseTag.length, endIdx > startIdx ? endIdx : response.length)
        : undefined;
    return kidText;
};
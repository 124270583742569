import { Spacer, SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { VFlex } from "../containers/VFlex";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { chatAiAutoSendState, chatAiHistoryState, chatAiIgnoreState, chatAiInputTextState } from "../../states/chatai";
import { QueryResponseEntry } from "./QueryResponseEntry";
import { BottomBar } from "./BottomBar";
import { HFlex } from "../containers/HFlex";
import { Launch } from "../ui/Launch";

export function ChatAiBox({backToTopCounter}: {backToTopCounter: number}) {
    const history = useRecoilValue(chatAiHistoryState);
    const ignoreIds = useRecoilValue(chatAiIgnoreState).map(q => q.id);
    const setInputText = useSetRecoilState(chatAiInputTextState);
    const setAutoSend = useSetRecoilState(chatAiAutoSendState);
    const firstElementRef = useRef(null);
    const firstContainer: any = firstElementRef.current;
    const lastElementRef = useRef(null);
    const lastContainer: any = lastElementRef.current;
    const [launchAnimationEnded, setLaunchAnimationEnded] = useState(false);
    const lastResponseRef = useRef<string | null>(null);
    const queries = history.filter(q => !ignoreIds.includes(q.id));

    useEffect(() => {
        const lastQuery = queries[queries.length - 1];
        if (lastQuery && lastQuery.response !== lastResponseRef.current) {
            lastContainer?.scrollIntoView({ behavior: "smooth" });
            lastResponseRef.current = lastQuery.response;
        }
    }, [lastElementRef, lastContainer, queries]);

    useEffect(() => {
        if (backToTopCounter > 0) {
            firstContainer?.scrollIntoView({ behavior: "smooth" });
        }
    }, [backToTopCounter]);

    const onStart = () => {
        setAutoSend(true);
        setInputText("START_BUDDY");
    };

    return (
        <VFlex style={{
            maxWidth: "1000px",
        }} spacing>
            <div ref={firstElementRef}/>
            {queries.length === 0 &&
                <Button kind="primary" size={"large"} onClick={onStart}>🚀 START 🚀</Button>
            }
            {queries.map((query, index) =>
                <QueryResponseEntry key={index} query={query} index={index}/>
            )}
            <BottomBar/>
            {queries.length === 1 && !launchAnimationEnded &&
                <HFlex>
                    <SpacerExpand/>
                    <Launch onAnimationEnd={() => {
                        setLaunchAnimationEnded(true)
                    }}/>
                    <SpacerExpand/>
                </HFlex>
            }
            <div ref={lastElementRef}/>
            <Spacer height={"48px"}/>
        </VFlex>
    )
}
import { atom } from "recoil";

export const audioRecorderBlobState = atom<Blob>({
    key: "audio.recorder.blob",
    default: undefined,
});

export const audioRecorderMediaTypeState = atom<string>({
    key: "audio.recorder.mediatype",
    default: undefined,
});

export const audioTranscriptionFetchingIdsState = atom({
   key: "audio.transcription.fetching.ids",
   default: [] as number[],
});

export const audioTranscriptionTextState = atom({
    key: "audio.transcription.text",
    default: "",
});

export const audioRecordingState = atom({
    key: "audio.recording",
    default: false,
});
export const luminance = (colorInHex: string | undefined): number => {
    if (!colorInHex) {
        return 1;
    }
    const r = parseInt(colorInHex.substring(1, 3), 16);
    const g = parseInt(colorInHex.substring(3, 5), 16);
    const b = parseInt(colorInHex.substring(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance;
}

export const contrastFilterColor = (backgroundColor: string | undefined) => {
    const lum = luminance(backgroundColor);
    return lum < 0.2 ? "invert(1)" : lum < 0.5 ? "brightness(1.8)" : undefined;
}
import { VFlex } from "../containers/VFlex";
import React, { ChangeEvent, useEffect, useRef } from "react";
import { ChatAiBox } from "./ChatAiBox";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    appBackgroundColorState,
    appBackToTopCounterState,
    appIsScrollingState,
    appShareObjectState,
    appShowSavedStoriesState,
    chatAiLoadingState,
} from "../../states/chatai";
import { HFlex } from "../containers/HFlex";
import { windowIsDesktopState } from "../../states/window";
import { HeadingLarge } from "baseui/typography";
import { SuggestionMarquee } from "./SuggestionMarquee";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { Player } from '@lottiefiles/react-lottie-player';
import { ShareModal } from "./ShareModal";
import { honeypotState } from "../../states/notARobotState";
import { useScrolling } from "../../hooks/useScrolling";
import { SavedStoriesModal } from "./SavedStoriesModal";
import { useWindowSize } from "usehooks-ts";
import { MenuRight } from "./MenuRight";
import { textShadow } from "../../utils/styling";
import { MenuLeft } from "./MenuLeft";

export function ChatAi() {
    const backgroundColor = useRecoilValue(appBackgroundColorState);
    const isDesktop = useRecoilValue(windowIsDesktopState);
    const backToTopCounter = useRecoilValue(appBackToTopCounterState);
    const loading = useRecoilValue(chatAiLoadingState);
    const lottieRef = useRef(null);
    const [honeypot, setHoneypot] = useRecoilState(honeypotState);
    const scrollingRef = useRef(null);
    const shareObject = useRecoilValue(appShareObjectState);
    const [showSavedStories,] = useRecoilState(appShowSavedStoriesState);
    const window = useWindowSize();
    const isPortrait = window.height * 0.71 > window.width;

    useScrolling(scrollingRef, appIsScrollingState);

    useEffect(() => {
        if (lottieRef.current) {
            const el = lottieRef.current as any;
            el.setSpeed(0.03);
        }
    }, [lottieRef?.current]);

    if (honeypot) {
        return <div/>;
    }

    return (
        <div style={{
            position: "relative",
            backgroundColor: backgroundColor,
            backgroundBlendMode: 'multiply',
            backgroundSize: '100px 100px',
            backgroundPosition: '0 0, 33px 33px, -33px -33px',
            width: "100vw",
            height: "100vh",
            transition: "background-color 3000ms ease-in-out",
        }}>
            <input type="text"
                   style={{ display: "none" }}
                   onInput={(e: ChangeEvent<HTMLInputElement>) => setHoneypot(e.target.value)}
            />
            <div style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
            }}>
                <Player
                    autoplay
                    loop={loading}
                    src="/anim/jungle.json"
                    style={{
                        width: isPortrait ? window.height : "100vw",
                        height: isPortrait ? window.height : "auto",
                        opacity: backgroundColor ? 0 : 1,
                        transition: "opacity 3000ms ease-in-out",
                    }}
                />
            </div>
            <div style={{
                transition: "background-color 3000ms ease-in-out",
                width: "100vw",
                height: "100vh",
                position: "absolute",
                top: 0,
                left: 0,
            }}>
                <VFlex
                    ref={scrollingRef}
                    style={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                        paddingLeft: isDesktop ? "28px" : "4px",
                        paddingRight: isDesktop ? "28px" : "4px",
                        flexGrow: 1,
                        position: "absolute",
                    }}
                    alignCenter
                >
                    <HFlex>
                        <SpacerExpand/>
                        <HeadingLarge style={{
                            color: 'white',
                            paddingLeft: window.width < 400 ? "10px" : '5vw',
                            paddingTop: window.width < 400 ? "10px" : '5vw',
                            paddingBottom: window.width < 400 ? "10px" : '5vw',
                            paddingRight: window.width < 400 ? "10px" : '5vw',
                            textAlign: "center",
                            fontFamily: "ArchitectsDaughter",
                            fontSize: "4.7vw",
                            fontWeight: "bold",
                            ...textShadow(8),
                        }}>
                            {"AI Buddy for Kids"}
                        </HeadingLarge>
                        <SpacerExpand/>
                    </HFlex>
                    <Spacer/>
                    {!shareObject && !showSavedStories && <SuggestionMarquee/>}
                    <Spacer height={"48px"}/>
                    <ChatAiBox backToTopCounter={backToTopCounter}/>
                </VFlex>
                <MenuRight/>
                <MenuLeft/>
            </div>
            <ShareModal/>
            <SavedStoriesModal/>
        </div>
    )
}
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { copyToClipboard } from "../../utils/app";
import { VFlex } from "../containers/VFlex";
import { LabelLarge, LabelXSmall } from "baseui/typography";
import { CheckCircledIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { appShareObjectState } from "../../states/chatai";
import { DogWaiting } from "../ui/DogWaiting";
import { StoryCard } from "./StoryCard";
import {
    FacebookIcon,
    FacebookShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { useTheme } from "../../hooks/useTheme";

export function ShareModal() {
    const { strings } = useTheme();
    const [object, setObject] = useRecoilState(appShareObjectState);

    useEffect(() => {
        if (object?.url) {
            copyToClipboard(object.url);
        }
    }, [object])

    if (!object) {
        return null;
    }

    return (
        <Modal
            onClose={() => setObject(undefined)}
            closeable
            isOpen
            animate
            autoFocus={false}
        >
            <ModalHeader>{strings.Options.SavingAndSharingLinks}</ModalHeader>
            <ModalBody>
                <VFlex spacing padding={16} alignCenter>
                    <Spacer/>
                    {object.loading && <DogWaiting/>}
                    {!object.loading && <>
                        <StoryCard object={object}/>
                        <LabelXSmall style={{ fontFamily: "Courier New" }}>{object.url}</LabelXSmall>
                        <Spacer height={"32px"}/>
                        <HFlex spacing alignCenter>
                            <SpacerExpand/>
                            <CheckCircledIcon size={24} color={"green"}/>
                            <Spacer/>
                            <LabelLarge>{strings.Options.UrlCopiedToClipboard}</LabelLarge>
                            <SpacerExpand/>
                        </HFlex>
                        {object.url && (
                            <HFlex spacing alignCenter style={{ paddingTop: "8px" }}>
                                <SpacerExpand/>
                                <LabelXSmall>{strings.Options.ShareWith}</LabelXSmall>
                                <TwitterShareButton
                                    title={object.title}
                                    url={object.url}
                                >
                                    <TwitterIcon size={32} round/>
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={object.url}
                                    title={object.title}
                                >
                                    <WhatsappIcon size={32} round/>
                                </WhatsappShareButton>
                                <RedditShareButton
                                    url={object.url}
                                    title={object.title}
                                    windowWidth={660}
                                    windowHeight={460}
                                >
                                    <RedditIcon size={32} round/>
                                </RedditShareButton>
                                <FacebookShareButton
                                    url={object.url}
                                    quote={object.title}
                                >
                                    <FacebookIcon size={32} round/>
                                </FacebookShareButton>
                                <SpacerExpand/>
                            </HFlex>
                        )}
                    </>}
                </VFlex>
            </ModalBody>
        </Modal>
    );
}
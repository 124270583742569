import { AiMessage, aiQuery, createSummary } from "../services/api";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
    appAudioSrcHistoryState,
    appAudioSrcPlayingState,
    appBackgroundColorState,
    appSavedStoriesState,
    appShareObjectState,
    chatAiHistoryState,
    chatAiInputTextState,
    chatAiLoadingState,
    storyImageState
} from "../states/chatai";
import { QueryResponse } from "../types/chatai";
import { uploadSaveHistory } from "../services/upload";
import { useNotARobot } from "./useNotARobot";
import { ShareObject } from "../types/share";
import { statQueryCounterState, statStoriesCounterState, statTokenCounterState } from "../states/statsState";

export function useChatAi() {
    const { isRobot } = useNotARobot();
    const setShareObject = useSetRecoilState(appShareObjectState);
    const setSavedStories = useSetRecoilState(appSavedStoriesState);
    const [queries, setQueries] = useRecoilState(chatAiHistoryState);
    const [loading, setLoading] = useRecoilState(chatAiLoadingState);
    const resetInputText = useResetRecoilState(chatAiInputTextState);
    const setAudioSrcHistory = useSetRecoilState(appAudioSrcHistoryState);
    const [audioSrcPlaying, setAudioSrcPlaying] = useRecoilState(appAudioSrcPlayingState);
    const storyImage = useRecoilValue(storyImageState);
    const storyColor = useRecoilValue(appBackgroundColorState);
    const savedStories = useRecoilValue(appSavedStoriesState);
    const setTokenCounter = useSetRecoilState(statTokenCounterState);
    const setStoryCounter = useSetRecoilState(statStoriesCounterState);
    const setQueryCounter = useSetRecoilState(statQueryCounterState);

    function createHistoryMessages(queries: QueryResponse[]) {
        const history: AiMessage[] = [];
        for (let q of queries) {
            history.push({ role: "user", content: q.query });
            history.push({ role: "assistant", content: q.response });
        }
        return history;
    }

    const sendQuery = async (queryString: string, onFinish: (query: QueryResponse) => void) => {
        if (loading) {
            return;
        }
        if (audioSrcPlaying) {
            setAudioSrcHistory(old => [...old, ...audioSrcPlaying]);
            setAudioSrcPlaying([]);
        }

        const currentId = Date.now();
        const newQuery = {
            query: queryString,
            response: "",
            id: currentId,
        } as QueryResponse;
        let lastUpdatedQuery = newQuery;
        const callback = (bufferUnfiltered: string) => {
            const idx = bufferUnfiltered.indexOf("[END-STREAM]");
            const buffer = idx > 0 ? bufferUnfiltered.substring(0, idx) : bufferUnfiltered;
            const updatedQuery = { ...newQuery, response: buffer };
            lastUpdatedQuery = updatedQuery;
            setQueries(old => [...old.filter(q => q.id !== currentId), updatedQuery]);
        };
        setQueries(old => [...old, newQuery]);

        const history = createHistoryMessages(queries);
        resetInputText();
        setLoading(true)
        const result = await aiQuery({ query: queryString, history }, callback);
        setTokenCounter(old => old + result.tokens);
        setQueryCounter(old => old + 1);
        onFinish && onFinish(lastUpdatedQuery);
        setLoading(false);
    };

    const shareStory = async (history: QueryResponse[] = queries, openModal = true) => {
        if (isRobot()) {
            console.debug("skip saving, robot")
            return;
        }
        if (history.length === 0) {
            console.debug("skip saving, empty")
            return;
        }
        setStoryCounter(old => old + 1);
        let isAlreadySaved = false;
        const stringified = JSON.stringify(history);
        for (let story of savedStories) {
            if (JSON.stringify(story.history) === stringified) {
                isAlreadySaved = true;
                break;
            }
        }
        if (isAlreadySaved && !openModal) {
            console.debug("skip saving, already saved")
            return;
        }

        if (isAlreadySaved && openModal) {
            const lastSavedStory = savedStories[0];
            setShareObject(lastSavedStory);
            return;
        }

        if (openModal) {
            setShareObject({ loading: true } as ShareObject);
        }

        const historyMessages = createHistoryMessages(history);
        const summaryResponse = await createSummary({ query: "SHARE_STORY", history: historyMessages }, () => {});
        const summary = summaryResponse?.status && JSON.parse(summaryResponse.plaintext);

        const shareObject = {
            title: summary?.title,
            description: summary?.description,
            history: history,
            image: storyImage,
            color: storyColor,
            ts: Date.now(),
            version: 1
        } as ShareObject;

        const shareString = JSON.stringify(shareObject);

        const historyBlob = new Blob([shareString], { type: 'application/json' });
        const file = new File([historyBlob], "audio");
        const response = await uploadSaveHistory({ file: file, extension: ".json" });
        if (response?.url) {
            const newShareObject = {
                ...shareObject,
                url: "https://ai.4kids.tech/share/" + response.url,
            } as ShareObject;
            if (openModal) {
                setShareObject(newShareObject);
            }
            setSavedStories(old => [newShareObject, ...old]);
        }
    }

    return { sendQuery, shareStory };
}
import { useEffectOnce } from 'usehooks-ts';
import { MutableRefObject, useState } from 'react';

export function useElementWidth(ref: MutableRefObject<HTMLDivElement>) {
    const [elementWidth, setElementWidth] = useState(0);

    useEffectOnce(() => {
        const wrapper = ref.current;
        const onResize = ([
                              {
                                  contentRect: { width },
                              },
                          ]: { contentRect: { width: number } }[]) => {
            setElementWidth(width);
        };

        let reziseObserver: ResizeObserver;

        if (wrapper) {
            reziseObserver = new ResizeObserver(onResize);
            reziseObserver.observe(wrapper);
        }

        return () => {
            if (wrapper) {
                reziseObserver?.unobserve(wrapper);
            }
        };
    });

    return { elementWidth };
}
import { Button } from "baseui/button";
import React, { useRef } from "react";
import { useSetRecoilState } from "recoil";
import { chatAiAutoSendState, chatAiInputTextState } from "../../states/chatai";
import { Image } from "../ui/Image";
import { Player } from "@lottiefiles/react-lottie-player";

export function SuggestionButton({
                                     text,
                                     src,
                                     imageUrl
                                 }: { text: string, src?: string, imageUrl?: string }) {
    const animationRef = useRef<any>(null);
    const setInputText = useSetRecoilState(chatAiInputTextState);
    const setAutoSend = useSetRecoilState(chatAiAutoSendState);

    const size = 128;

    const onClick = () => {
        setAutoSend(true);
        setInputText(text);
    };

    return (
        <Button kind={"tertiary"} ref={animationRef} onClick={onClick}>
            {src && <Player ref={animationRef}
                            src={src}
                            loop
                            speed={0.2}
                            style={{ width: size + "px", height: size + "px" }}
            />}
            {imageUrl && <Image src={imageUrl} size={size}/>}
        </Button>
    );
}
import { useStyletron } from "baseui";
import { useRecoilValue } from "recoil";
import { stringsState } from "../states/localeState";

type Formatted = number | string;

export function useTheme() {
    const [css, theme] = useStyletron();
    const strings = useRecoilValue(stringsState);
    const format = (str: string, ...values: Formatted[]) => {
        for (let i = 0; i < values.length; i++) {
            if (!values[i] && values[i] !== 0) {
                values[i] = "";
            }
        }
        return strings.formatString(str, ...values) as string;
    }

    return { css, theme, strings, format };
}
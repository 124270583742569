import { sha256 } from "../utils/security";

type Mode = "PROD" | "LOCAL";

export const MODE: Mode = "PROD";
export const LOCAL_DOMAIN = 'localhost:8888';
export const PROD_DOMAIN = "ai.4kids.tech";

// @ts-ignore
const SCHEMA = MODE === "LOCAL" ? "http://" : "https://";
// @ts-ignore
export const API_DOMAIN = MODE === "LOCAL" ? LOCAL_DOMAIN : PROD_DOMAIN;
export const API_ENDPOINT = SCHEMA + API_DOMAIN + "/api";

export const STATUS_OK = 0;

export enum Endpoint {
    kidsaiquery = "kidsai/query",
    kidsaitexttospeech = "kidsai/texttospeech",
    kidsaistreamedtts = "kidsai/streamedtts",
    kidsaiimagegeneration = "kidsai/imagegeneration",
    kidsairestorehistory = "kidsai/restorehistory",
    kidsaicreatesummary = "kidsai/createsummary",
    uploadaudiotranscription = 'uploadfile/audiotranscription',
    uploadsavehistory = "uploadfile/savehistory",
}

export function prepareRequest(dataObj = {}) {
    return { data: dataObj };
}

export const token = '60439ecc-3f99-4690-8cd3-4618a31ee6e8';

export const headers = async (data: string) => ({
    'Content-Type': 'application/json',
    'Signature': await sha256(data + token),
});

export const fetchFromServer = async (endpoint: Endpoint, dataObj: object) => {
    const url = API_ENDPOINT + '/' + endpoint;
    const body = JSON.stringify(prepareRequest(dataObj));
    const result = await fetch(url, {
        method: 'POST',
        body,
        headers: await headers(body),
    });

    if (result.ok) {
        const json = await result.json();
        if (json.status === STATUS_OK) {
            return { status: true, data: json };
        } else {
            return { status: false, data: {}, error: json.status };
        }
    } else {
        return { status: false, data: {}, error: result.status };
    }
}

export const fetchFromServerStreamed = async (endpoint: Endpoint, dataObj: object, callback: (buffer: string) => void) => {
    const url = API_ENDPOINT + '/' + endpoint;
    const body = JSON.stringify(prepareRequest(dataObj));
    const response = await fetch(url, {
        method: 'POST',
        body,
        headers: await headers(body),
    });
    if (!response.ok || !response.body) {
        return { status: false, data: {}, error: response.status };
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let full = '';
    let done = false;
    while (!done) {
        const { value, done: readerDone } = await reader.read();
        if (value) {
            const chunk = decoder.decode(value, { stream: true });
            full += chunk;
            callback(full);
        }
        if (readerDone) {
            done = true;
        }
    }
    const tailIdx = full.indexOf("[END-STREAM]");
    const tail = full.substring(tailIdx + "[END-STREAM]".length);
    try {
        const json = JSON.parse(tail);
        if (json.status === STATUS_OK) {
            return { status: true, data: json };
        } else {
            return { status: false, data: {}, error: json.status };
        }
    } catch {
        return { status: false, data: {} };
    }
}

export const fetchAudio = async (endpoint: Endpoint, dataObj: object) => {
    const url = API_ENDPOINT + '/' + endpoint;
    const body = JSON.stringify(prepareRequest(dataObj));
    const response = await fetch(url, {
        method: 'POST',
        body: body,
        headers: await headers(body),
    });
    if (!response.ok || !response.body) {
        return undefined;
    }

    const arrayBuffer = await response.arrayBuffer();
    let blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
    return URL.createObjectURL(blob);
}


export interface AiMessage {
    role: "assistant" | "user";
    content: string;
}

export interface AiRequest {
    query: string;
    history: AiMessage[];
}

export const aiQuery = async (request: AiRequest, streamCallback: (buffer: string) => void) => {
    const response = await fetchFromServerStreamed(Endpoint.kidsaiquery, request, streamCallback);
    return {
        status: response.status,
        plaintext: response.data.plaintext as string,
        tokens: response.data.tokens as number
    };
}

export const createSummary = async (request: AiRequest, streamCallback: (buffer: string) => void) => {
    const response = await fetchFromServerStreamed(Endpoint.kidsaicreatesummary, {
        ...request,
        query: "SHARE_STORY"
    }, streamCallback);
    return {
        status: response.status,
        plaintext: response.data.plaintext as string,
    };
}

export type Voice = "male" | "female";

export interface TextToSpeechRequest {
    text: string;
    lang?: string;
    model?: "elevenlabs" | "google";
    voice?: Voice;
}

export const textToSpeech = async (request: TextToSpeechRequest) => {
    const url = await fetchAudio(Endpoint.kidsaitexttospeech, request);
    if (!url) {
        return { status: false, url: "" };
    }
    return {
        status: true,
        url: url,
    }
}

export const streamedTtsUrl = async (tts: TextToSpeechRequest) => {
    const signature = await sha256(tts.text + token);
    const queryJsonStringified = JSON.stringify({
        data: { ...tts },
        signature,
    });
    return API_ENDPOINT + '/' + Endpoint.kidsaistreamedtts + "?" + encodeURIComponent(queryJsonStringified);
}

export const imageGeneration = async (prompt: string) => {
    const response = await fetchFromServer(Endpoint.kidsaiimagegeneration, { prompt });
    return {
        status: response.status,
        url: response.data.url,
        uuid: response.data.uuid,
    }
}

export const restoreHistory = async (uuid: string) => {
    const response = await fetchFromServer(Endpoint.kidsairestorehistory, { uuid });
    return {
        status: response.status,
        plaintext: response.data.plaintext,
    }
}
import { ButtonWithTts } from "../ui/ButtonWithTts";
import { ArrowUpIcon, BookmarksIcon, NewStoryIcon, SocialSharing, VolumeIcon, VolumeMuteIcon } from "../ui/svg";
import { VFlex } from "../containers/VFlex";
import { LabelXSmall } from "baseui/typography";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
    appAudioOnState,
    appBackgroundColorState,
    appBackToTopCounterState,
    appShowSavedStoriesState, chatAiHistoryState
} from "../../states/chatai";
import { windowIsDesktopState } from "../../states/window";
import { useChatAi } from "../../hooks/useChatAi";
import { contrastFilterColor, luminance } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";

export function MenuLeft() {
    const { strings, theme } = useTheme();
    const backgroundColor = useRecoilValue(appBackgroundColorState);
    const { shareStory } = useChatAi();
    const setShowSavedStories = useSetRecoilState(appShowSavedStoriesState);
    const history = useRecoilValue(chatAiHistoryState);
    const resetHistory = useResetRecoilState(chatAiHistoryState);

    const onNewStoryClick = () => {
        resetHistory();
        shareStory(history, false);
    }

    const onSaveStoryClick = async () => await shareStory();
    const onShowSavedStories = () => setShowSavedStories(true);

    return (
        <VFlex spacing style={{
            position: "fixed", top: 0,
            left: 0,
            paddingLeft: "8px",
            paddingTop: "8px",
        }}>
            <ButtonWithTts
                kind={"secondary"}
                tts={strings.Options.NewStory}
                shape={"circle"}
                onClick={onNewStoryClick}
            >
                <NewStoryIcon size={24} color={backgroundColor || theme.colors.contentPrimary} style={{
                    filter: contrastFilterColor(backgroundColor),
                }}/>
            </ButtonWithTts>
            <ButtonWithTts
                kind={"primary"}
                tts={strings.Options.SaveShareStory}
                shape={"circle"}
                onClick={onSaveStoryClick}
            >
                <SocialSharing size={24} color={theme.colors.backgroundPrimary} style={{
                    filter: contrastFilterColor(backgroundColor),
                }}/>
            </ButtonWithTts>
            <ButtonWithTts
                kind={"primary"}
                tts={strings.Options.ShowSavedStories}
                shape={"circle"}
                onClick={onShowSavedStories}
            >
                <BookmarksIcon size={24} color={theme.colors.backgroundPrimary} style={{
                    filter: contrastFilterColor(backgroundColor),
                }}/>
            </ButtonWithTts>
        </VFlex>
    )
}